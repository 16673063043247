import { useEffect, useState } from "react";
import {Col, Row, Button, Form} from "react-bootstrap";
import OrderProgress from "../Components/OrderProgress";
import Layout from "../Components/Shared/Layout";
import OrderProgressState from "../Components/ProgressState/ProgressState";
import AddressForm from "../Components/OrderProgress/AddressForm";
import UserInformationForm from "../Components/OrderProgress/userInformationForm";
import Products from "../Components/OrderProgress/Products";
import { UseContextProvider } from "../contexts/StepperContext";
import ConfirmedOrder from "../Components/OrderProgress/ConfirmedOrder";
import ReviewOrder from "../Components/OrderProgress/ReviewOrder";
import validator from "validator";
import axios from "axios";
import { useParams } from "react-router-dom";

function getFormValues() {
  const storedValues = localStorage.getItem("val_order_data");
  if (!storedValues)
    return {
      postalCode: "",
      houseNumber: "",
      extrahouseNumberLetter: "",
      streetName: "",
      city: "",
      partnerCode: "",
      partnerCodeId: 0,
      products: [
        {
          id: 1,
          name: "smartBike",
          quantity: 0,
        },
        {
          id: 2,
          name: "Battery_551",
          quantity: 0,
        },
        {
          id: 3,
          name: "Sticker",
          quantity: 0,
        },
        {
          id: 4,
          name: "bike_Type_1",
          quantity: 0
        }
      ],
      contract: 24,
      smartBike: 0,
      bike_Type_1: 0,
      Battery_551: 0,
      totalCostExc: 0,
      totalCostInc: 0,
      weeklyCostExc: 0,
      weeklyCostInc: 0,
      totalSticker: 0,
      sticker: false,

      companyName: "",
      vatNumber: "",
      vatCompanyName: "",
      vatCompanyAddress: "",
      KvK: "",
      IBAN: "",
      bankName: "",
      firstName: "",
      lastName: "",
      middleName: "",
      phoneNumber: "",
      email: "",
      reference: "",
      newsLetter: true,

      imageFile: null,
      imageSrc: "",
      imageName: "",

      acceptTerms: false,
    };
  return JSON.parse(storedValues);
}

const HomePage = () => {
  const [formData, setFormData] = useState(getFormValues);
  const [currentStep, setCurrentStep] = useState(0);
  const steps = ["address", "products", "User Information", "Review Order"];
  const [validPostalCode, setValidPostalCode] = useState(true);
  const [validHouseNumber, setValidHouseNumber] = useState(true);
  const [validPartnerCode, setValidPartnerCode] = useState(true);

  const [validProductQuantity, setValidProductQuantity] = useState(true);

  const [validPhone, setValidPhone] = useState(true);
  const [validBank, setValidBank] = useState(true);
  const [ validVatNumber, setValidVatNumber ] = useState(true);

  const [errors, setErrors] = useState({});
  const [isLoading, setLoading] = useState(false);

  const [inputPartnerCode, setInputPartnerCode] = useState(false);
  const { partnerCodeValue } = useParams();

  const { contractDuration } = useParams();

  useEffect(() => {
    if (partnerCodeValue && partnerCodeValue !== "") {
      axios
        .get(`api/partnerCode/getCodeId/${partnerCodeValue}`)
        .then(function (resp) {
          if (resp.data.length > 0) {
            var vip = resp.data[0].vip;
            if (vip === true) {
              formData.partnerCodeId = 2;
              setInputPartnerCode(true);
              setValidPartnerCode(true);
              formData.partnerCode = partnerCodeValue;
            } else {
              formData.partnerCodeId = 1;
              setInputPartnerCode(true);
              setValidPartnerCode(true);
              formData.partnerCode = partnerCodeValue;
            }
          } else {
            setValidPartnerCode(false);
            setInputPartnerCode(false);
            formData.partnerCode = "";
            formData.partnerCodeId = 0;
          }
        });
    }
  });

  useEffect(() => {
    if (contractDuration) {
      switch (contractDuration) {
        case "12":
         formData.contract = 12;
         break;
        case "24":
         formData.contract = 24;
         break;
        case "9":
         formData.contract = 9;
         break;
        default:
         formData.contract = 24;
      }
    }
  }, [formData.contract]);

  const redirect = () => {
    window.location.href = "/";
  };

  const setField = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const findFormErrors = () => {
    const {
      postalCode,
      houseNumber,
      vatNumber,
      KvK,
      IBAN,
      bankName,
      firstName,
      lastName,
      phoneNumber,
      email,
      acceptTerms,
      sticker,
      totalSticker
    } = formData;
    const newErrors = {};
    if (validator.isEmpty(postalCode))
      newErrors.postalCode = "Vul de postcode in";
    if (!validator.isEmpty(postalCode) && !validPostalCode)
      newErrors.postalCode = "Onjuiste postcode";

    if (validator.isEmpty(houseNumber))
      newErrors.houseNumber = "Vul het huisnummer in";
    if (!validHouseNumber) newErrors.houseNumber = "Onjuist Huisnummer";

    if (!validPartnerCode) newErrors.partnerCode = "Onjuiste Partnercode";

    if (currentStep === 1 && !validProductQuantity)
      newErrors.validProduct = "Bestel minimaal één fiets";

    if (currentStep === 1 && formData.bike_Type_1 > 0 && formData.contract === 9) {
      newErrors.productContractDurationInvalid = "Bezorgfiets Type I kan niet worden besteld met een contractduur van 9 maanden. Kies een andere contractduur.";
    }    
      
      if (currentStep === 1 && sticker && totalSticker === 0)
      newErrors.validTotalSticker = "Je moet minimaal 1 fiets laten bestickeren";

    if (currentStep === 2 && validator.isEmpty(KvK))
      newErrors.KvK =
        "Onjuist KvK-nummer. Zoek je bedrijf op door het invullen van een deel van je bedrijfsnaam. Kies dan het juiste bedrijf.";

    if (currentStep === 2 && vatNumber === "")
      newErrors.vatNumber = "Vul je BTW-nummer in";
    
    if (currentStep === 2 && !validVatNumber)
      newErrors.vatNumber = "Ongeldig BTW-nummer"

    if (currentStep === 2 && validator.isEmpty(IBAN))
      newErrors.IBAN = "Vul het IBAN in";
    if (currentStep === 2 && !validBank) newErrors.IBAN = "Onjuist IBAN";

    if (currentStep === 2 && validator.isEmpty(bankName))
      newErrors.bankName = "Vul de tenaamstelling van je bankrekening in";

    if (currentStep === 2 && validator.isEmpty(firstName))
      newErrors.firstName = "Vul de voornaam in";

    if (
      currentStep === 2 &&
      !validator.isEmpty(firstName) &&
      firstName.length < 3
    )
      newErrors.firstName = "Voornaam te kort";

    if (currentStep === 2 && validator.isEmpty(lastName))
      newErrors.lastName = "Vul de achternaam in";

    if (
      currentStep === 2 &&
      !validator.isEmpty(lastName) &&
      lastName.length < 3
    )
      newErrors.lastName = "Achternaam te kort";

    if (currentStep === 2 && validator.isEmpty(phoneNumber))
      newErrors.phoneNumber = "Vul het telefoonnummer in";

    if (currentStep === 2 && !validPhone)
      newErrors.phoneNumber = "Onjuist telefoonnummer";

    if (currentStep === 2 && validator.isEmpty(email))
      newErrors.email = "Vul een geldig e-mailadres in";

    if (
      currentStep === 2 &&
      !validator.isEmpty(email) &&
      !validator.isEmail(email)
    )
      newErrors.email = "Onjuist e-mailadres";

    if (currentStep === 3 && !acceptTerms)
      newErrors.acceptTerms =
        "Accepteer onze algemene voorwaarden om door te gaan";

    return newErrors;
  };

  const handleClick = (direction) => {
    let newStep = currentStep;
    const newErrors = findFormErrors();
    if (direction === "back") {
      newStep--;
      newStep >= 0 && setCurrentStep(newStep);
    }
    if (direction === "next") {
      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
      } else {
        newStep++;
        newStep >= 0 && newStep <= steps.length && setCurrentStep(newStep);
        localStorage.setItem("val_order_data", JSON.stringify(formData));
      }
    }
    if (direction === "post") {
      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
      } else {
        setLoading(true);
        axios
          .post("api/newOrder/Order", formData)
          .then((resp) => {
            if (resp.status === 200) {
              setCurrentStep(4);
              setLoading(false);
              localStorage.removeItem("val_order_data");
            }
          })
          .catch((error) => {});
      }
    }
  };

  const displayStep = (step) => {
    switch (step) {
      case 0:
        return (
          <AddressForm
            values={formData}
            validPostalCode={setValidPostalCode}
            validHouseNumber={setValidHouseNumber}
            partnerCode={setValidPartnerCode}
            errors={errors}
            inputField={setField}
            inputPartnerCodeField={inputPartnerCode}
          />
        );
      case 1:
        return (
          <Products
            inputField={setField}
            values={formData}
            errors={errors}
            validProduct={setValidProductQuantity}
          />
        );
      case 2:
        return (
          <UserInformationForm
            values={formData}
            checkPhone={setValidPhone}
            checkBank={setValidBank}
            validVatNumber={setValidVatNumber}
            errors={errors}
            inputField={setField}
          />
        );
      case 3:
        return (
          <ReviewOrder
            values={formData}
            errors={errors}
            inputField={setField}
          />
        );
      case 4:
        return <ConfirmedOrder />;
      default:
    }
  };

  return (
    <Row className="vh-100 p-0 m-0">
      {/* Show carousel layout */}
      <Col lg={9} style={{ backgroundColor: "white", position: "relative" }}>
        <Row className="">
        <Layout />
        </Row>
      </Col>
      {/* Show Component Layout */}
      <Col lg={3} style={{ backgroundColor: "#ececec", position: "relative" }} className=" pb-4">
        {/* Show ProgressState Lind */}
        <OrderProgressState steps={steps} currentStep={currentStep} />
        {/* Show Components */}
        <UseContextProvider
          value={{
            addressForm: "",
            productsForm: "",
            userInformationForm: "",
            reviewOrder: "",
          }}
        >
          {displayStep(currentStep)}
        </UseContextProvider>
        {/*Show form buttons */}
        {partnerCodeValue ? (
          !inputPartnerCode ? (
            <div className="d-flex align-items-center flex-column">
              <div>
                <p className="text-danger">De partnercode is niet (meer) geldig.</p>
              </div>
              <div>
                <Button className="next_button" onClick={redirect}>
                  Sluiten
                </Button>
              </div>
            </div>
          ) : (
            currentStep !== steps.length && <OrderProgress
              handleClick={handleClick}
              currentStep={currentStep}
              steps={steps}
              loading={isLoading}
            />
          )
        ) : (
          currentStep !== steps.length && (
            <OrderProgress
              handleClick={handleClick}
              currentStep={currentStep}
              steps={steps}
              loading={isLoading}
            />
          )
        )}
      </Col>
    </Row>
  );
};
export default HomePage;
