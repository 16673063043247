// import { createContext } from "react";

// export const StepperContext = createContext(null);

import { createContext, useContext, useState } from "react";

const StepperContext = createContext({ orderData: "", setOrderData: null });

export function UseContextProvider({ children }) {
  const [orderData, setOrderData] = useState("");

  return (
    <StepperContext.Provider value={{ orderData, setOrderData }}>
      {children}
    </StepperContext.Provider>
  );
}

export function useStepperContext() {
  const { orderData, setOrderData } = useContext(StepperContext);
  return { orderData, setOrderData };
}