import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import axios from "axios";
import AsyncSelect from "react-select/async";
import "./orderProgress.css";
/* eslint-disable */
export default function UserInformationForm({
  values,
  checkPhone,
  checkBank,
  validVatNumber,
  inputField,
  errors,
}) {
  const [companyError, setCompanyError] = useState("");
  const [vatData, setVatData] = useState("");

  function validatePhoneNumber(value) {
    var regex =
      /^((\+|00(\s|\s?\-\s?)?)31(\s|\s?\-\s?)?(\(0\)[\-\s]?)?|0)[1-9]((\s|\s?\-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/;
    return regex.test(value);
  }

  function validateNlBankNumber(value) {
    var regex = /^NL[0-9]{2}[A-z0-9]{4}[0-9]{10}$/;
    return regex.test(value);
  }

  const checkKvKNumber = (inputValue) => {
    if (inputValue !== "") {
      return axios
        .get(`api/companyDetails/companyName/${inputValue}`)
        .then((resp) => {
          if (resp.data.resultaten) {
            const res = resp.data.resultaten;
            inputField("companyName", res[0].handelsnaam);
            return res;
          } else if (resp.data.fout) {
            setCompanyError(resp.data.fout[0].omschrijving);
          }
        });
    }
  };
  const handleChange = (searchTerm) => {
    inputField("KvK", searchTerm.kvkNummer);
    values.companyName = searchTerm.handelsnaam;
  };

  const checkVatNumber = (inputValue) => {
    if (inputValue !== "") {
      return axios.get(`api/vatInformation/NL/${inputValue}`).then((resp) => {
        if (resp.status === 200) {
          updateVatDetails(resp.data.name, resp.data.address);
          inputField("vatNumber", resp.data.vatNumber);
          setVatData(resp.data);
          validVatNumber(true);
        } else {
          inputField("vatNumber", "");
          setVatData("");
          validVatNumber(false);
        }
      });
    } else {
      inputField("vatNumber", "");
      setVatData("");
      validVatNumber(true);
    }
  };

  function updateVatDetails(name, address) {
    values.vatCompanyName = name;
    values.vatCompanyAddress = address;
  }

  const checkNlPhoneNumber = (e) => {
    var phoneNumber = e.target.value;
    if (phoneNumber !== "") {
      var deleteSpaces = phoneNumber.replace(/\s/g, "");
      var inputPhoneNumber = validatePhoneNumber(deleteSpaces);
      if (!inputPhoneNumber) {
        checkPhone(false);
      } else {
        inputField("phoneNumber", phoneNumber);
        checkPhone(true);
      }
    } else {
      inputField("phoneNumber", "");
    }
  };

  const checkNlBankAccount = (e) => {
    var bankNumber = e.target.value;
    if (bankNumber !== "") {
      var deleteSpaces = bankNumber.replace(/\s/g, "");
      var inputBankNumber = validateNlBankNumber(deleteSpaces);
      if (!inputBankNumber) {
        checkBank(false);
      } else {
        inputField("IBAN", bankNumber);
        checkBank(true);
      }
    } else {
      inputField("IBAN", "");
    }
  };

  return (
    <div>
      <div>
        <div className="d-flex justify-content-center">
          <h3 className="mainTitle">Bedrijfsgegevens</h3>
        </div>
        <Form>
          <Row className="mb-3">
            <Col>
              <h6>Bedrijfsnaam</h6>
              <AsyncSelect
                cacheOptions
                defaultOptions
                placeholder={values.companyName || "Select"}
                getOptionLabel={(e) => e.handelsnaam + " - " + e.kvkNummer}
                loadOptions={checkKvKNumber}
                onChange={handleChange}
                noOptionsMessage={() => companyError}
              />
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <h6>KvK-nummer</h6>
              <Form.Group className="mb-3">
                <Form.Control
                  onChange={(e) => inputField("KvK", e.target.value)}
                  defaultValue={values.KvK || ""}
                  name="KvK"
                  type="text"
                  placeholder="KvK-nummer"
                  isInvalid={!!errors.KvK}
                  disabled
                />
                <Form.Control.Feedback type="invalid">
                  {errors.KvK}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <h6>BTW-nummer</h6>
            <Col className="d-flex flex-row align-items-center">
              <p className="pe-2">NL</p>
              <Form.Group className="mb-3 w-100">
                <Form.Control
                  onChange={(e) => checkVatNumber(e.target.value)}
                  defaultValue={values.vatNumber || ""}
                  name="vatNumber"
                  type="text"
                  placeholder="BTW-nummer"
                  isInvalid={!!errors.vatNumber}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.vatNumber}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            {vatData !== "" ? (
              <Row style={{minHeight: "35px"}}>
                <Col md={4}>
                  <p style={{ fontSize: "0.8rem" }}>{vatData.name}</p>
                </Col>
                <Col md={8}>
                  <p style={{ fontSize: "0.8rem" }}>{vatData.address}</p>
                </Col>
              </Row>
            ) : (
              <Row style={{minHeight: "35px"}}></Row>
            )}
          </Row>
          <Row className="mb-2">
            <Col>
              <h6>Bankrekeningnummer (IBAN)</h6>
              <Form.Group className="mb-3">
                <Form.Control
                  onChange={checkNlBankAccount}
                  defaultValue={values.IBAN || ""}
                  name="IBAN"
                  type="text"
                  placeholder="IBAN"
                  isInvalid={!!errors.IBAN}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.IBAN}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-2">
            <Col>
              <h6>Ten name van</h6>
              <Form.Group className="mb-3">
                <Form.Control
                  onChange={(e) => inputField("bankName", e.target.value)}
                  defaultValue={values.bankName || ""}
                  name="bankName"
                  type="text"
                  placeholder="Ten name van"
                  isInvalid={!!errors.bankName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.bankName}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <hr />
          <div>
            <h4>Adresgegevens</h4>
          </div>
          <Row>
            <Col sm={6}>
              <Form.Group className="mb-3">
                <Form.Control
                  required
                  onChange={() => {}}
                  value={values.streetName}
                  name="streetName"
                  type="text"
                  placeholder="Straat"
                  disabled
                />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group className="mb-3">
                <Form.Control
                  required
                  onChange={() => {}}
                  value={values.houseNumber}
                  name="houseNumber"
                  type="number"
                  placeholder="Huisnummer"
                  disabled
                />
                <Form.Control.Feedback type="invalid">
                  {errors.houseNumber}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm={2}>
              <Form.Group className="mb-3">
                <Form.Control
                  onChange={() => {}}
                  value={values.extrahouseNumberLetter}
                  name="extrahouseNumberLetter"
                  type="text"
                  placeholder="Toev."
                  disabled
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Control
                  required
                  onChange={() => {}}
                  value={values.postalCode}
                  name="postalCode"
                  type="text"
                  placeholder="Postcode"
                  disabled
                />
                <Form.Control.Feedback type="invalid">
                  {errors.postalCode}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Control
                  required
                  onChange={() => {}}
                  value={values.city}
                  name="city"
                  type="text"
                  placeholder="Plaats"
                  disabled
                />
              </Form.Group>
            </Col>
          </Row>
          <hr />
          <div>
            <h4>Contactpersoon</h4>
          </div>
          <Row>
            <Col sm={8}>
              <Form.Group className="mb-3">
                <Form.Control
                  required
                  onChange={(e) => inputField("firstName", e.target.value)}
                  value={values.firstName}
                  name="firstName"
                  type="text"
                  placeholder="Voornaam"
                  isInvalid={!!errors.firstName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.firstName}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group className="mb-3">
                <Form.Control
                  onChange={(e) => inputField("middleName", e.target.value)}
                  value={values.middleName}
                  name="middleName"
                  type="text"
                  placeholder="Tussenvoegsels"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Control
                  required
                  onChange={(e) => inputField("lastName", e.target.value)}
                  value={values.lastName}
                  name="lastName"
                  type="text"
                  placeholder="Achternaam"
                  isInvalid={!!errors.lastName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.lastName}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Control
                  required
                  onChange={checkNlPhoneNumber}
                  defaultValue={values.phoneNumber || ""}
                  name="phoneNumber"
                  type="number"
                  placeholder="Telefoonnummer"
                  isInvalid={!!errors.phoneNumber}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.phoneNumber}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Control
                  required
                  onChange={(e) => inputField("email", e.target.value)}
                  value={values.email}
                  name="email"
                  type="email"
                  placeholder="E-mail"
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label style={{ fontWeight: "bold" }}>
                  Hoe ben je bij ons terecht gekomen?
                </Form.Label>
                <Form.Select
                  onChange={(e) => inputField("reference", e.target.value)}
                  aria-label="Default select example"
                >
                  <option defaultValue={""}>Maak een keuze</option>
                  <option value="Via social media">Via social media</option>
                  <option value="Via een advertentie">
                    Via een advertentie
                  </option>
                  <option value="Benaderd door Bezorgfiets Nederland">
                    Benaderd door Bezorgfiets Nederland
                  </option>
                  <option value="Benaderd door een partner van Bezorgfiets Nederland">
                    Benaderd door een partner van Bezorgfiets Nederland
                  </option>
                  <option value="Via vrienden of familie">
                    Via vrienden of familie
                  </option>
                  <option value="Via het internet">Via het internet</option>
                </Form.Select>
                <br />
                <Form.Control
                  style={{ display: "none", height: "100px" }}
                  id="descriptionReview"
                  as="textarea"
                  placeholder="Beschrijf hier hoe je bij ons bent gekomen"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3 fw-bold">
                <Form.Check
                  onChange={(e) => inputField("newsLetter", e.target.checked)}
                  type="checkbox"
                  id="formNewsPaper"
                  label="Ik wil de Bezorgfiets Nederland Nieuwsbrief ontvangen"
                  defaultChecked={values.newsLetter}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}
