import { Button } from "react-bootstrap";

const clearFinishedOrder = () => {

  const redirect = () => {
    window.location.href = "/";
  };

  return (
    <>
      <div className="text-center">
        <h1>Bedankt voor je bestelling!</h1> <br />
        <h5>We gaan zo snel mogelijk met je bestelling aan de slag.</h5> <br />
          <p>Houd er rekening mee dat je bestelling pas definitief is nadat deze door Bezorgfiets Nederland is geaccepteerd.</p>
        <p>
          Voor het maken van een afspraak voor de levering van de
          bezorgfiets(en) en andere praktische zaken nemen we contact met je op.
        </p>
      </div>
      <div className="d-flex justify-content-center">
        <Button className="next_button" onClick={redirect}>
          Sluiten
        </Button>
      </div>
    </>
  );
};

export default clearFinishedOrder;
