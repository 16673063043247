import { useState } from "react";
import { Carousel, Row, Col } from "react-bootstrap";
import "./Layout.css";

const Layout = () => {
  const smallScreen = window.innerWidth;
  return (
    <Col lg={9} style={smallScreen < 992 ? {position: "relative"} : {position: "fixed"}}>
      <div className="logo">
        <img
          src="/Images/cropped-Bezorgfiets-Nederland.png"
          className="d-flex relative"
          alt="bezorgfiets"
        ></img>
      </div>
      <Row className="ratio ratio-16x9">
        <Carousel>
          <Carousel.Item className="ratio ratio-4x4">
            <img src="/Images/deelfiets_Blue_1.webp" alt="First slide" />
          </Carousel.Item>
          <Carousel.Item className="ratio ratio-4x4">
            <img
              src="/Images/deelfiets_Blue_2.webp"
              alt="Second slide"
            />
          </Carousel.Item>
          {/* <Carousel.Item className="ratio ratio-4x4">
            <img src="/Images/Grijs bezorgfiets af 2.png" alt="Third slide" />
          </Carousel.Item> */}
        </Carousel>
      </Row>
    </Col>
  );
};
export default Layout;
