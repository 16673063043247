import { Button } from "react-bootstrap";
import "./orderProgress.css";
import { ArrowLeft, ArrowRight } from "react-bootstrap-icons";

const OrderProgress = ({ handleClick, currentStep, steps, loading }) => {
  return (
    <>
      <div className="d-flex flex-row justify-content-around">
        <Button
          className="back_button"
          id="BackButton"
          onClick={() => handleClick("back")}
          hidden={currentStep === 0 ? true : false}
        >
          <ArrowLeft className="back_icon" size={14} />
          Vorige
        </Button>
        {currentStep === steps.length - 1 ? (
          <Button className="next_button" disabled={loading} onClick={() => handleClick("post")}>
            {loading ? "Nu bestellen..." : "Nu bestellen"}
          </Button>
        ) : (
          <Button
            className="next_button"
            id="NextButton"
            onClick={() => handleClick("next")}
          >
            Volgende <ArrowRight className="back_icon" size={14} />
          </Button>
        )}
      </div>
    </>
  );
};
export default OrderProgress;
