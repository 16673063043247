import { Card, Row, Col, Table, Form } from "react-bootstrap";

export default function ReviewOrder({ values, errors, inputField }) {
  const totalWeeklyBTW = (values.weeklyCostExc / 100) * 21;

  const totalWeeklyCostIncBwt = values.weeklyCostExc + totalWeeklyBTW;

  // Should pay per stuk
  const totalOrderCost = values.products[0].quantity * 35;
  const totalOneTimeCostExl = totalOrderCost + values.totalCostExc + 50;

  const oneTimeBTW = (totalOneTimeCostExl / 100) * 21;
  const totalOneTimeCostIncBtw = oneTimeBTW + totalOneTimeCostExl;

  return (
    <>
      <div className="text-center">
        <h1>Overzicht</h1>
        <h5>We hebben alle gegevens verzameld. Dit ga je bestellen.</h5>
      </div>
      <br />
      {values.bike_Type_1 ? (
        <Card className="mb-2">
          <Row className="p-2">
            <Col xs={5} className="d-flex display-relative">
              <div className="ratio ratio-4x3">
                <img
                  src="/Images/deelfiets_Type_incl_box_zwart.jpg"
                  alt="bezorgfiets"
                  width={100}
                ></img>
              </div>
            </Col>
            <Col
              xs={5}
              className="d-flex align-middle justify-content-center flex-column"
            >
              <p className="fw-bold">Bezorgfiets Type I</p>
            </Col>
            <Col
              xs={2}
              className="d-flex flex-column align-items-center justify-content-center"
              style={{ zIndex: "20" }}
            >
              <div className="fw-bold fs-5">({values.bike_Type_1})</div>
            </Col>
          </Row>
        </Card>
      ) : (
        ""
      )}
      {values.smartBike ? (
        <Card className="mb-2">
          <Row className="p-2">
            <Col xs={5} className="d-flex display-relative">
              <div className="ratio ratio-4x3">
                <img
                  src="/Images/deelfiets_Blue_1.webp"
                  alt="bezorgfiets"
                  width={100}
                ></img>
              </div>
            </Col>
            <Col
              xs={5}
              className="d-flex align-middle justify-content-center flex-column"
            >
              <p className="fw-bold">Slimme Bezorgfiets</p>
            </Col>
            <Col
              xs={2}
              className="d-flex flex-column align-items-center justify-content-center"
              style={{ zIndex: "20" }}
            >
              <div className="fw-bold fs-5">({values.smartBike})</div>
            </Col>
          </Row>
        </Card>
      ) : (
        ""
      )}
      {values.Battery_551 ? (
        <Card className="mb-2">
          <Row className="p-2">
            <Col xs={5} className="d-flex display-relative">
              <div className="ratio ratio-4x3">
                <img src="/Images/Accu.png" alt="bezorgfiets" width={100}></img>
              </div>
            </Col>
            <Col
              xs={5}
              className="d-flex align-middle justify-content-center flex-column"
            >
              <p className="fw-bold">Extra accu</p>
            </Col>
            <Col
              xs={2}
              className="d-flex flex-column align-items-center justify-content-center"
              style={{ zIndex: "20" }}
            >
              <div className="fw-bold fs-5">({values.Battery_551})</div>
            </Col>
          </Row>
        </Card>
      ) : (
        ""
      )}
      <h6>Per bezorgfiets berekenen wij eenmalig € 35,00 aansluitkosten.</h6>
      <hr />
      <h4>Totale kosten per week</h4>
      <Table striped bordered responsive="sm">
        <tbody>
          <tr>
            <td style={{ fontSize: "0.8rem" }}>Totaal per week (Excl. BTW):</td>
            <td style={{ fontSize: "0.8rem" }}>
              € {Number(values.weeklyCostExc).toFixed(2)}
            </td>
          </tr>
          <tr>
            <td className="fw-bold" style={{ fontSize: "0.8rem" }}>
              BTW (21%):
            </td>
            <td className="fw-bold" style={{ fontSize: "0.8rem" }}>
              € {Number(totalWeeklyBTW).toFixed(2)}
            </td>
          </tr>
          <tr style={{ backgroundColor: "gray" }}>
            <td className="fw-bold text-light" style={{ fontSize: "0.8rem" }}>
              Totaal per week (Incl. BTW):
            </td>
            <td className="fw-bold text-light" style={{ fontSize: "0.8rem" }}>
              € {Number(totalWeeklyCostIncBwt).toFixed(2)}
            </td>
          </tr>
        </tbody>
      </Table>
      <hr />
      <h4>Totaal eenmalige kosten</h4>
      <Table striped bordered responsive="sm">
        <tbody>
          {values.totalCostExc === 0 ? (
            ""
          ) : (
            <tr>
              <td style={{ fontSize: "0.8rem" }}>
                Eenmalige kosten voor de bestickering
              </td>
              <td style={{ fontSize: "0.8rem" }}>
                € {Number(values.totalCostExc).toFixed(2)}
              </td>
            </tr>
          )}

          <tr>
            <td style={{ fontSize: "0.8rem" }}>Bestelkosten:</td>
            <td style={{ fontSize: "0.8rem" }}>€ 50,00</td>
          </tr>
          <tr>
            <td style={{ fontSize: "0.8rem" }}>Aansluitkosten:</td>
            <td style={{ fontSize: "0.8rem" }}>
              € {Number(totalOrderCost).toFixed(2)}
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: "0.8rem" }}>Totaal (Excl. BTW):</td>
            <td style={{ fontSize: "0.8rem" }}>
              € {Number(totalOneTimeCostExl).toFixed(2)}
            </td>
          </tr>
          <tr>
            <td className="fw-bold" style={{ fontSize: "0.8rem" }}>
              BTW (21%):
            </td>
            <td className="fw-bold" style={{ fontSize: "0.8rem" }}>
              € {Number(oneTimeBTW).toFixed(2)}
            </td>
          </tr>
          <tr style={{ backgroundColor: "gray" }}>
            <td className="fw-bold text-light" style={{ fontSize: "0.8rem" }}>
              Totaal Eenmalige kosten (Incl. BTW):
            </td>
            <td className="fw-bold text-light" style={{ fontSize: "0.8rem" }}>
              € {Number(totalOneTimeCostIncBtw).toFixed(2)}
            </td>
          </tr>
        </tbody>
      </Table>
      <h6>
        Je bestelling is onder voorbehoud van acceptatie door Bezorgfiets
        Nederland.
      </h6>
      <hr />
      Je privacy staat bij ons voorop. Lees in{" "}
      <a
        href="https://www.bezorgfietsnederland.nl/wp-content/uploads/2023/03/Privacy-verklaring-Bezorgfiets-Nederland.pdf"
        target="_blank"
        rel="noreferrer"
      >
        onze privacyverklaring
      </a>{" "}
      wat wij doen met je persoonlijke informatie. Op de diensten van
      Bezorgfiets Nederland zijn algemene voorwaarden van toepassing. Deze
      voorwaarden kun je{" "}
      <a
        href="https://bezorgfietsnederland.nl/algemene-voorwaarden"
        target="_blank"
        rel="noreferrer"
      >
        hier downloaden
      </a>
      .
      <br />
      <Form className="mt-2 mb-4">
        <Form.Check
          type="checkbox"
          label="Ja, ik ga akkoord met de algemene voorwaarden."
          onChange={(e) => inputField("acceptTerms", e.target.checked)}
          defaultChecked={values.acceptTerms}
          isInvalid={!!errors.acceptTerms}
        />
        <Form.Control.Feedback>{errors.acceptTerms}</Form.Control.Feedback>
      </Form>
    </>
  );
}
