import React, { useEffect, useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import axios from "axios";

export default function AddressInformation({
  values,
  inputField,
  errors,
  validPostalCode,
  validHouseNumber,
  partnerCode,
  inputPartnerCodeField
}) {
  const [addressData, setAddressData] = useState({
    street: "",
    city: "",
  });

  function autoUpdateAddressInformation(streetName, cityName) {
    values.streetName = streetName;
    values.city = cityName;
  }

  function getPartnerCodeId(id, code) {
    values.partnerCodeId = id;
    values.partnerCode = code;
  }

  function validatePostalCode(value) {
    var regex = /^[1-9][0-9]{3}[\s]?[A-Za-z]{2}$/i;
    return regex.test(value);
  }

  useEffect(() => {
    fGetAddressFromPostal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.postalCode, values.houseNumber]);

  const fGetAddressFromPostal = () => {
    var postalCodeField = values.postalCode;
    var houseNumberField = values.houseNumber;
    setAddressData({ street: "", city: "" });
    if (postalCodeField && houseNumberField) {
      var postalCode = postalCodeField.replace(/\s/g, "");
      var postalStatus = validatePostalCode(postalCode);
      if (!postalStatus) {
        validPostalCode(false);
        setAddressData({ street: "", city: "" });
      } else if (postalStatus && houseNumberField) {
        axios
          .get(`api/pro6pp/autocomplete/${postalCode}/${houseNumberField}`)
          .then(function (resp) {
            if (resp.data.status === "error") {
              validHouseNumber(false);
              setAddressData({ street: "", city: "" });
            } else {
              validHouseNumber(true);
              validPostalCode(true);
              autoUpdateAddressInformation(
                resp.data.results[0].street,
                resp.data.results[0].city
              );
              setAddressData({
                street: resp.data.results[0].street,
                city: resp.data.results[0].city,
              });
            }
          });
        setAddressData({ street: "", city: "" });
      }
    } else {
      setAddressData({ street: "", city: "" });
    }
  };

  const checkPartnerCode = (e) => {
    const code = e.target.value;
    if (code && code !== "") {
      axios.get(`api/partnerCode/getCodeId/${code}`).then(function (resp) {
        if (resp.data.length > 0) {
          var vip = resp.data[0].vip;
          if (vip){
            getPartnerCodeId(2, resp.data[0].code);
            partnerCode(true);
            inputField("partnerCode", e.target.value);
          }
          else {
            getPartnerCodeId(1, resp.data[0].code);
            partnerCode(true);
            inputField("partnerCode", e.target.value);
          }
        } else {
          partnerCode(false);
          inputField("partnerCode", e.target.value);
        }
      });
    } else {
      partnerCode(true);
      getPartnerCodeId(0, e.target.value);
    }
  };

  return (
    <>
      <div>
        <div className="addressForm">
          <div>
            <div className="d-flex justify-content-center">
              <h3 className="mainTitle">Bezorgfiets aanvragen</h3>
            </div>
            <div style={{ marginBottom: "10em" }}>
              <p className="text-center" style={{ fontWeight: "bold" }}>
                Welkom bij Bezorgfiets Nederland! Wat leuk dat je een
                bezorgfiets bij ons wilt aanvragen. Om verder te gaan hebben wij
                jouw adresgegevens nodig.
              </p>
            </div>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Postcode</Form.Label>
                <Form.Control
                  onChange={(e) => inputField("postalCode", e.target.value)}
                  defaultValue={values.postalCode}
                  name="postalCode"
                  type="text"
                  required
                  placeholder="Postcode"
                  isInvalid={!!errors.postalCode}
                />

                <Form.Control.Feedback type="invalid">
                  {errors.postalCode}
                </Form.Control.Feedback>
              </Form.Group>
              <Row>
                <Col sm={8}>
                  <Form.Group className="mb-4">
                    <Form.Label>Huisnummer</Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        inputField("houseNumber", e.target.value)
                      }
                      defaultValue={values.houseNumber}
                      name="houseNumber"
                      type="text"
                      required
                      placeholder="Huisnr."
                      isInvalid={!!errors.houseNumber}
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.houseNumber}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col sm={4}>
                  <Form.Label>Toev.</Form.Label>
                  <Form.Group className="mb-3">
                    <Form.Control
                      onChange={(e) =>
                        inputField("extrahouseNumberLetter", e.target.value)
                      }
                      defaultValue={values.extrahouseNumberLetter}
                      name="extrahouseNumberLetter"
                      type="text"
                      placeholder="Toev."
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div>
                <p>
                  <span className="fw-bold">Straat:</span>{" "}
                  {addressData.street ? addressData.street : ""}
                </p>
              </div>
              <div>
                <p>
                  <span className="fw-bold">Plaats:</span>{" "}
                  {addressData.city ? addressData.city : ""}
                </p>
              </div>
              <hr />
              <Form.Group className="mb-3 mt-4">
                <Form.Label>
                  Bestel je via een{" "}
                  <span style={{ fontWeight: "bold" }}>partner</span>? <br />
                  Vul dan hieronder je partnercode in. <br />
                </Form.Label>
                <Form.Control
                  onChange={checkPartnerCode}
                  defaultValue={values.partnerCode}
                  name="partnerCode"
                  type="text"
                  placeholder="Vul je partnercode in"
                  disabled={inputPartnerCodeField}
                  isInvalid={values.partnerCode !== "" && !!errors.partnerCode}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.partnerCode}
                </Form.Control.Feedback>
              </Form.Group>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
