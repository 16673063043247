import {
  Card,
  Button,
  Row,
  Col,
  Form,
  CloseButton,
  Table,
  Container,
  Alert,
} from "react-bootstrap";
import React, { useEffect, useState } from "react";
import "./Products.css";
import axios from "axios";

const Products = ({ values, validProduct, errors }) => {
  const [frontBoxQuantity, setfrontBoxQuantity] = useState(0);
  const [bikeTypeQuantity, setBikeTypeQuantity] = useState(0);
  const [accuQuantity, setAccuQuantity] = useState(0);
  const [previewImage, setPreviewImage] = useState("display-block");
  const [imagePath, setImagePath] = useState("");
  const [contract, setContract] = useState(values.contract);

  const [bikePrice, setBikePrice] = useState(0);
  const [bikeTypePrice, setBikeTypePrice] = useState(0);
  const [stickerPrice, setStickerprice] = useState(0);
  const [accuPrice, setAccuPrice] = useState(0);

  const [withSticker, setWithSticker] = useState(values.sticker);

  const totalBikePriceFront = values.smartBike * bikePrice;
  const totalBikeTypePrice = values.bike_Type_1 * bikeTypePrice;
  const totalAccuPrice = values.Battery_551 * accuPrice;
  const total = totalBikePriceFront + totalAccuPrice + totalBikeTypePrice;

  useEffect(() => {
    if (values.smartBike !== 0 || values.bike_Type_1 !== 0) {
      validProduct(true);
      values.weeklyCostExc = total;
    } else {
      validProduct(false);
    }
  });

  const onChangeValue = (e) => {
    var duration = e.target.value;
    var changeToNumber = parseInt(duration, 0);
    values.contract = changeToNumber;
    setContract(changeToNumber);
  };

  const showPreview = (e) => {
    let showImage = e.target.files[0];
    const reader = new FileReader();

    if (e.target.files && e.target.files[0]) {
      values.imageFile = e.target.files[0];
      reader.onload = (x) => {
        values.imageSrc = x.target.result;
        setImagePath(x.target.result);
      };
      reader.readAsDataURL(showImage);
    } else {
      values.imageFile = null;
      values.imageSrc = "";
      setImagePath("");
    }
  };
  const deleteImage = () => {
    setPreviewImage("display-none");
    values.imageFile = null;
    values.imageSrc = "";
    setImagePath("");
  };

  const addLogo = (e) => {
    values.sticker = e.target.checked;
    setWithSticker(e.target.checked);
    if (!e.target.checked) {
      values.imageFile = null;
      values.imageSrc = "";
      setImagePath("");
      values.totalSticker = 0;
      values.products[2].quantity = 0;
      values.totalCostExc = 0;
    }
  };

  const updateTotalSticker = (e) => {
    const totalStickeredExc = e.target.value * stickerPrice;
    var roundedTopTotal = totalStickeredExc.toFixed(2);
    var correctTotalStickered = Number(roundedTopTotal);

    var total = e.target.value;
    var changeToNumber = parseInt(total, 0);
    values.totalSticker = changeToNumber;
    values.totalCostExc = correctTotalStickered;
    values.products[2].quantity = changeToNumber;
  };

  useEffect(() => {
    const partnerId = values.partnerCodeId;
    axios
      .get(`api/productPrices/GetPrices/${1}/${contract}/${partnerId}`)
      .then(function (resp) {
        if (resp) {
          setBikePrice(resp.data[0].priceExc);
        }
      })
      .catch((error) => {});
    axios
      .get(`api/productPrices/GetPrices/${2}/${contract}/${partnerId}`)
      .then(function (resp) {
        if (resp) {
          setAccuPrice(resp.data[0].priceExc);
        }
      })
      .catch((error) => {});
    axios
      .get(`api/productPrices/GetPrices/${3}/${contract}/${partnerId}`)
      .then(function (resp) {
        if (resp) {
          setStickerprice(resp.data[0].priceExc);
        }
      })
      .catch((error) => {});
    axios
      .get(`api/productPrices/GetPrices/${4}/${contract}/${partnerId}`)
      .then(function (resp) {
        if (resp) {
          setBikeTypePrice(resp.data[0].priceExc);
        }
      })
      .catch((error) => {});
  }, [values.contract]);

  return (
    <div>
      <div
        className="d-flex justify-content-center"
      >
        <h3 className="mainTitle">Bezorgfietsen en opties kiezen</h3>
      </div>
      {errors.productContractDurationInvalid ? (
          <Alert variant="danger">{errors.productContractDurationInvalid}</Alert>
      ) : (
          ""
      )}
      <div
        className="w-100 d-flex justify-content-around mb-4"
        onChange={onChangeValue}
      >
        <Form>
          <>
            <Row className="d-flex flex-row">
              <Card
                className={"mb-2 radio-border_inline_class"}
                style={
                  values.contract === 9
                    ? { borderColor: "#08123B" }
                    : { borderColor: "#ffffff" }
                }
              >
                <div className="d-flex justify-content-md-center flex-column">
                  <Form.Check
                    inline
                    type="radio"
                    value="9"
                    name="contract"
                    className="d-flex justify-content-center"
                    checked={values.contract === 9}
                    onChange={() => {}}
                  />
                  <p style={{ fontSize: "0.8rem" }}>9 Maanden</p>
                </div>
              </Card>

              <Card
                className={"mb-2 radio-border_inline_class"}
                style={
                  values.contract === 12
                    ? { borderColor: "#08123B" }
                    : { borderColor: "#ffffff" }
                }
              >
                <div className="d-flex justify-content-md-center flex-column">
                  <Form.Check
                    inline
                    type="radio"
                    value="12"
                    name="contract"
                    className="d-flex justify-content-center"
                    checked={values.contract === 12}
                    onChange={() => {}}
                  />
                  <p style={{ fontSize: "0.8rem" }}>12 Maanden</p>
                </div>
              </Card>

              <Card
                className={"radio-border_inline_class"}
                style={
                  values.contract === 24
                    ? { borderColor: "#08123B" }
                    : { borderColor: "#ffffff" }
                }
              >
                <div className="d-flex justify-content-md-center flex-column">
                  <Form.Check
                    inline
                    type="radio"
                    value="24"
                    name="contract"
                    className="d-flex justify-content-center"
                    checked={values.contract === 24}
                    onChange={() => {}}
                  />
                  <p style={{ fontSize: "0.8rem" }}>24 Maanden</p>
                </div>
              </Card>
            </Row>           
          </>          
        </Form>
      </div>
      {values.contract === 0 && contract === 0 ? (
        ""
      ) : (
        <Container>
          <h6 className="ps-4">Hoeveel bezorgfietsen wil je afnemen?</h6>
          {/* Bike Type I */ values.contract !== 9 ? (
            <>
          <Card
            className={"rounded-5 border_inline_class"}
            style={
              values.bike_Type_1 > 0
                ? { borderColor: "#08123B" }
                : { borderColor: "#ffffff" }
            }
          >
            <Row className="p-2">
              <Col xs={5} className="d-flex display-relative">
                <div className="ratio ratio-4x3">
                  <img
                    src="/Images/deelfiets_Type_incl_box_zwart.jpg"
                    alt="bezorgfiets"
                    width={100}
                  ></img>
                </div>
              </Col>
              <Col
                xs={5}
                className="d-flex align-middle justify-content-center flex-column"
              >
                <p className="fw-bold" style={{ fontSize: "0.8em" }}>
                  Bezorgfiets Type I
                </p>
              </Col>
              <Col
                xs={2}
                className="d-flex flex-column align-items-center justify-content-center"
                style={{ zIndex: "20" }}
              >
                <Button
                  onClick={() => {
                    values.bike_Type_1++;
                    setBikeTypeQuantity(bikeTypeQuantity + 1);
                    values.totalSticker = 0;
                    values.totalCostExc = 0;
                    values.products[3].quantity = values.bike_Type_1;
                  }}
                  className="rounded-pill p-xl-2 p-1 fw-bold"
                  size="sm"
                  style={{ backgroundColor: "#08123B" }}
                >
                  &#8963;
                </Button>
                <div className="fw-bold fs-5">{values.bike_Type_1}</div>
                <Button
                  onClick={() => {
                    values.bike_Type_1 > 0 && values.bike_Type_1--;
                    setBikeTypeQuantity(bikeTypeQuantity - 1);
                    values.totalSticker = 0;
                    values.totalCostExc = 0;
                    values.products[3].quantity = values.bike_Type_1;
                  }}
                  className="rounded-pill p-xl-2 p-1 fw-bold"
                  size="sm"
                  style={{ backgroundColor: "#08123B" }}
                >
                  &#8964;
                </Button>
              </Col>
            </Row>
          </Card>
          <div className="d-flex justify-content-end me-2 mb-4">
            <p
              className="me-4 p-2 SubTotal"
              style={
                values.bike_Type_1 > 0
                  ? {
                      fontSize: "0.8em",
                      fontWeight: "bold",
                      borderColor: "#08123B",
                    }
                  : {
                      fontSize: "0.8em",
                      fontWeight: "bold",
                      borderColor: "#ffffff",
                    }
              }
            >
              € {Number(totalBikeTypePrice).toFixed(2)} per week
            </p>
          </div>
          </>
          ) : "" /* Bike Type I Card */}
          <Card
            className={"rounded-5 border_inline_class"}
            style={
              values.smartBike > 0
                ? { borderColor: "#08123B" }
                : { borderColor: "#ffffff" }
            }
          >
            <Row className="p-2">
              <Col xs={5} className="d-flex display-relative">
                <div className="ratio ratio-4x3">
                  <img
                    src="/Images/deelfiets_Blue_1.webp"
                    alt="bezorgfiets"
                    width={100}
                  ></img>
                </div>
              </Col>
              <Col
                xs={5}
                className="d-flex align-middle justify-content-center flex-column"
              >
                <p className="fw-bold" style={{ fontSize: "0.8em" }}>
                  Slimme Bezorgfiets
                </p>
              </Col>
              <Col
                xs={2}
                className="d-flex flex-column align-items-center justify-content-center"
                style={{ zIndex: "20" }}
              >
                <Button
                  onClick={() => {
                    values.smartBike++;
                    setfrontBoxQuantity(frontBoxQuantity + 1);
                    values.totalSticker = 0;
                    values.totalCostExc = 0;
                    values.products[0].quantity = values.smartBike;
                  }}
                  className="rounded-pill p-xl-2 p-1 fw-bold"
                  size="sm"
                  style={{ backgroundColor: "#08123B" }}
                >
                  &#8963;
                </Button>
                <div className="fw-bold fs-5">{values.smartBike}</div>
                <Button
                  onClick={() => {
                    values.smartBike > 0 && values.smartBike--;
                    setfrontBoxQuantity(frontBoxQuantity - 1);
                    values.totalSticker = 0;
                    values.totalCostExc = 0;
                    values.products[0].quantity = values.smartBike;
                  }}
                  className="rounded-pill p-xl-2 p-1 fw-bold"
                  size="sm"
                  style={{ backgroundColor: "#08123B" }}
                >
                  &#8964;
                </Button>
              </Col>
            </Row>
          </Card>
          <div className="d-flex justify-content-end me-2 mb-4">
            <p
              className="me-4 p-2 SubTotal"
              style={
                values.smartBike > 0
                  ? {
                      fontSize: "0.8em",
                      fontWeight: "bold",
                      borderColor: "#08123B",
                    }
                  : {
                      fontSize: "0.8em",
                      fontWeight: "bold",
                      borderColor: "#ffffff",
                    }
              }
            >
              € {Number(totalBikePriceFront).toFixed(2)} per week
            </p>
          </div>
          {/* Extra Battery Card */}
          <div>
            <h6 className="ps-4">Wil je extra accu's bij de bezorgfietsen?</h6>
            <Card
              className="rounded-5 border_inline_class"
              style={
                values.Battery_551 > 0
                  ? { borderColor: "#08123B" }
                  : { borderColor: "#ffffff" }
              }
            >
              <Row className="p-2">
                <Col xs={5} className="d-flex display-relative">
                  <div className="ratio ratio-4x3">
                    <img
                      src="/Images/Accu.png"
                      alt="bezorgfiets"
                      width={100}
                    ></img>
                  </div>
                </Col>
                <Col
                  xs={5}
                  className="d-flex align-middle justify-content-center flex-column"
                >
                  <p className="fw-bold" style={{ fontSize: "0.8em" }}>
                    Extra accu
                  </p>
                </Col>
                <Col
                  xs={2}
                  className="d-flex flex-column align-items-center justify-content-center"
                  style={{ zIndex: "20" }}
                >
                  <Button
                    onClick={() => {
                      values.Battery_551++;
                      setAccuQuantity(accuQuantity + 1);
                      values.products[1].quantity = values.Battery_551;
                    }}
                    className="rounded-pill p-xl-2 p-1 fw-bold"
                    size="sm"
                    style={{ backgroundColor: "#08123B" }}
                  >
                    &#8963;
                  </Button>
                  <div className="fw-bold fs-5">{values.Battery_551}</div>
                  <Button
                    onClick={() => {
                      values.Battery_551 > 0 && values.Battery_551--;
                      setAccuQuantity(accuQuantity - 1);
                      values.products[1].quantity = values.Battery_551;
                    }}
                    className="rounded-pill p-xl-2 p-1 fw-bold"
                    size="sm"
                    style={{ backgroundColor: "#08123B" }}
                  >
                    &#8964;
                  </Button>
                </Col>
              </Row>
            </Card>
            <div className="d-flex justify-content-end me-2 mb-4">
              <p
                className="me-4 p-2 SubTotal"
                style={
                  values.Battery_551 > 0
                    ? {
                        fontSize: "0.8em",
                        fontWeight: "bold",
                        borderColor: "#08123B",
                      }
                    : {
                        fontSize: "0.8em",
                        fontWeight: "bold",
                        borderColor: "#ffffff",
                      }
                }
              >
                € {Number(totalAccuPrice).toFixed(2)} per week
              </p>
            </div>
          </div>
        </Container>
      )}
      {/* Upload sticker */}
      {values.smartBike === 0 && values.bike_Type_1 === 0 ? (
        errors.validProduct ? (
          <Alert variant="danger">{errors.validProduct}</Alert>
        ) : (
          ""
        )
      ) : (
        <div>
          <hr />
          <div className="mb-2 ms-4">
            <h6>
              Wij kunnen je bezorgfietsen voorzien van bestickering in je eigen
              huisstijl.
            </h6>
            <p className="fw-bold">
              Kosten: eenmalig € {Number(stickerPrice).toFixed(2)} per
              bezorgfiets
            </p>
            <Form>
              <Form.Check
                type="checkbox"
                label="Ik wil mijn bezorgfietsen graag laten bestickeren"
                onChange={addLogo}
                defaultChecked={withSticker}
              />
            </Form>
          </div>
          {withSticker ? (
            <Form>
              <div className="mb-2">
                Hoeveel bezorgfietsen wil je laten bestickeren?
              </div>
              <div className="mb-4">
                <Card>
                  <Form.Select
                    aria-label="Default select example"
                    onChange={updateTotalSticker}
                    defaultValue={
                      values.totalSticker > 0 ? values.totalSticker : 0
                    }
                  >
                    {(() => {
                      let number = [];
                      for (let i = 0; i <= (values.smartBike + values.bike_Type_1); i++) {
                        number.push(
                          <option key={i} value={i}>
                            {i}
                          </option>
                        );
                      }
                      return number;
                    })()}
                  </Form.Select>
                </Card>
                {withSticker && values.totalSticker === 0 ? (
                  <div>
                    <span className="text-danger">
                      {errors.validTotalSticker}
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </div>

              {values.imageSrc !== "" ? (
                <div
                  className={`m-2 d-flex justify-content-center w-full ${previewImage}`}
                >
                  {imagePath !== "" ? (
                    <img src={imagePath} width="240px" alt="logo" />
                  ) : (
                    <img src={values.imageSrc} width="240px" alt="logo" />
                  )}
                  <CloseButton onClick={deleteImage} />
                </div>
              ) : (
                ""
              )}
              <h6>Upload hier je logo</h6>
              <Card className="mb-4">
                <Form.Group>
                  <Form.Control
                    type="file"
                    onChange={showPreview}
                    accept="image/png, .jpeg, .jpg, .ai"
                  />
                </Form.Group>
              </Card>
            </Form>
          ) : (
            ""
          )}
          <hr />
          {/* Show total weekly cost */}
          <Table>
            <tbody>
              <tr>
                <td className="fw-bold" style={{ fontSize: "0.8rem" }}>
                  Per week (Excl. BTW)
                </td>
                <td className="fw-bold" style={{ fontSize: "0.8rem" }}>
                  € {Number(total).toFixed(2)}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      )}
    </div>
  );
};

export default Products;
