import React, { useEffect, useState, useRef } from "react";
import "./ProgressState.css";

const OrderProgressState = ({ steps, currentStep }) => {
  const [newStep, setNewStep] = useState([]);
  const stepRef = useRef();

  const updateStep = (stepNumber, steps) => {
    const newSteps = [...steps];
    let count = 0;
    while (count < newSteps.length) {
      if (count === stepNumber) {
        newSteps[count] = {
          ...newSteps[count],
          highlighted: true,
          selected: true,
          completed: true,
        };
        count++;
      } else if (count < stepNumber) {
        newSteps[count] = {
          ...newSteps[count],
          highlighted: false,
          selected: true,
          completed: true,
        };
        count++;
      } else {
        newSteps[count] = {
          ...newSteps[count],
          highlighted: false,
          selected: false,
          completed: false,
        };
        count++;
      }
    }
    return newSteps;
  };

  useEffect(() => {
    const stepsState = steps.map((step, index) =>
      Object.assign(
        {},
        {
          completed: false,
          highlighted: index === 0 ? true : false,
          selected: index === 0 ? true : false,
        }
      )
    );
    stepRef.current = stepsState;
    const current = updateStep(currentStep - 1, stepRef.current);
    setNewStep(current);
  }, [steps, currentStep]);

  const displaySteps = newStep.map((step, index) => {
    return (
      <div
        key={index}
        className={
          index !== newStep.length - 1
            ? "w-100 d-flex justify-space-between align-items-center"
            : "d-flex align-items-center"
        }
      >
        <div
          className="position-relative d-flex flex-column align-items-center"
          style={{ color: "#08123B" }}
        >
          <div
            className={`rounded-circle border border-2 border-light transition duration-500 ease-in-out d-flex justify-content-center `}
            style={
              step.selected
                ? {
                    width: "30px",
                    height: "30px",
                    backgroundColor: "#08123B",
                    color: "#ffffff",
                  }
                : { width: "30px", height: "30px" }
            }
          >
            {step.completed ? (
              <span className="text-light fw-bold ts-xl">&#10003;</span>
            ) : (
              index + 1
            )}
          </div>
        </div>
        <div className={`${step.completed ? "completed" : "incomplete"}`}>
          {/* Show line */}
        </div>
      </div>
    );
  });
  return (
    <div className="mx-4 ps-1 pe-1 pt-4 pb-4 d-flex justify-content-between align-items-center">
      {displaySteps}
    </div>
  );
};

export default OrderProgressState;
